// import React from 'react';
import ReactDOM from 'react-dom/client';
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import LoadingSite from './loading-site';
import './index.css';
import './index-tablet.css';
import './index-mobile.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


function RootComponent() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const hasLoaded = sessionStorage.getItem('hasLoaded');
    if (hasLoaded) {
      setIsLoading(false);
    } else {
      sessionStorage.setItem('hasLoaded', 'true');
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <BrowserRouter>
      {isLoading ? <LoadingSite /> : <App />}
    </BrowserRouter>
  );
}


root.render(
  // <React.StrictMode>
    <RootComponent />
  // </React.StrictMode>
);
