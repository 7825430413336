import React from 'react';
import './new-arrivals.css';

const NewArrivals: React.FC = () => {

    interface ItemBrand {
        name: string;
        pathImg: string;
    }

    const imgBrand:ItemBrand[] = [
        {name:"versace", pathImg:"/assets/dev-site/shop-co/brends/brend_1.png"},
        {name:"zara", pathImg:"/assets/dev-site/shop-co/brends/brend_2.png"},
        {name:"gucci", pathImg:"/assets/dev-site/shop-co/brends/brend_3.png"},
        {name:"prada", pathImg:"/assets/dev-site/shop-co/brends/brend_4.png"},
        {name:"calvin klein", pathImg:"/assets/dev-site/shop-co/brends/brend_5.png"},
    ];

    return (
        <div className="new-arrivals-container">
            <h2 className="new-arrivals-h2">NEW ARRIVALS</h2>
            <div className="new-arrivals-items-container">
                
            </div>
        </div>
    );
};

export default NewArrivals;