import React from 'react';
import './header.css';

const Header: React.FC = () => {
    return (
        <div className="header-container">
            <header className='header-site-shop-co'>
                <div className="site-shop-co-logo">
                    <a href="/dev-sites/site-shop-co">SHOP.CO</a>
                </div>
                <nav className="navigation">
                    <ul>
                        <li>
                            <a href="">Shop</a>
                        </li>
                        <li>
                            <a href="">On Sale</a>
                        </li>
                        <li>
                            <a href="">New Arrivals</a>
                        </li>
                        <li>
                            <a href="">Brands</a>
                        </li>
                    </ul>
                </nav>
                <div className="search-container">
                    search
                </div>
                <div className="account-basket">
                    <div className="icon-block">
                        <a href="" className="icon-block-link">
                            <img src="/assets/dev-site/shop-co/account-basket/basket.png"
                                alt="basket"
                                className="icon-block-img"
                            />
                        </a>
                        <a href="" className="icon-block-link">
                            <img src="/assets/dev-site/shop-co/account-basket/account.png"
                                alt="basket"
                                className="icon-block-img"
                            />
                        </a>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;